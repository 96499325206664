
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.wrapper {
    background-color: #fff;
    padding: 80px 0;
    @apply font-bold;
    h2 {
        @apply text-2xl;
    }
    p {
        @apply text-base;
        @include md {
            @apply text-lg;
        }
    }
    h3 {
        @apply text-lg;
    }
    li {
        span {
            @apply text-base;

            @include md {
                @apply text-lg;
            }
        }
    }
}
